import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HeroText } from "../Hero/Hero";
import classes from "./home.module.css";
import { MdEmail } from "react-icons/md";

const Home = () => {
  const [activeFaq, setActiveFaq] = useState(null);

  // Function to toggle FAQ items
  const toggleFaq = (faqId) => {
    console.log(`Toggle FAQ: ${faqId}`);
    // If the clicked FAQ is already active, close it, otherwise open the clicked FAQ
    setActiveFaq(activeFaq === faqId ? null : faqId);
  };

  return (
    <>
      {/* header - start */}
      <div className="header position-absolute">
        <div className="header-container container d-flex justify-content-between align-items-center">
          <div className="logo-div d-flex align-items-center ">
            <Link to="/">
              <img
                style={{
                  width: "250px",
                  height: "40px",
                  filter: "invert(100%) brightness(0)",
                }}
                className={classes.mainlogo}
                src="https://ik.imagekit.io/qz6ubt34n4/logo-whiteAsset%202.png?updatedAt=1696619947825"
                alt=""
              />
            </Link>
            {/* <Link to='/' className='mb-0'>Prepcrazy</Link> */}
          </div>
          {/* <div className="buttons">
                        <Link to="/signin">Sign in</Link>
                        <Link to="/signup">Sign up</Link>
                    </div> */}
        </div>
      </div>
      {/* header - end */}

      {/* home */}

      <div className="home">
        <div className="home-container container">
          <HeroText />

          <div className="showcase d-flex justify-content-evenly align-items-center"></div>

          {/* <div className="stores-buttons text-center">
                        <a href="##"><img src="https://ik.imagekit.io/99djpd8k3/Resource%20Snap.png?updatedAt=1707134717882" alt="" /></a>
                        <a href="##"><img src="https://ik.imagekit.io/99djpd8k3/Resource%20Snap.png?updatedAt=1707134717882" alt="" /></a>
                    </div> */}

          <div className={classes.how}>
            <p className={classes.titlemain}>
              Learning <span style={{ color: "#009598" }}>begins</span> here!
            </p>
            <p className={classes.desc_h}>
              Explore how our platform enhances your coding journey with
              essential tools. Access learning materials, a real-time code
              compiler, and tech blogs for innovation. Designed to inspire and
              guide, we provide the resources for success
            </p>
          </div>

          {/* row 1 */}
          <div className="how-it-works-row row">
            <div className=" col-sm-6 position-relative">
              {/* <img
                src={process.env.PUBLIC_URL + "/assets/images/step-1-g.svg"}
                alt=""
              /> */}
              <img
                className={classes.image_1}
                src="/assets/images/playground.svg"
                alt=""
                width={600}
                height={500}
              />
            </div>

            <div className="step-para col-sm-6">
              <p className={classes.title} id="code-playground">
                <span style={{ color: "#009598" }}>Code</span> Playground
              </p>
              <p className={classes.desc}>
                Our code compiler playground offers a seamless coding
                experience, enabling you to write, test, and refine your code in
                real-time.
              </p>
              <p className={classes.desc}>
                With support for multiple languages, it's the perfect tool for
                both learning and advanced development.
              </p>
              <a
                className={classes.btns}
                href="https://playground.prepcrazy.com/"
              >
                Code now
              </a>
            </div>
          </div>

          {/* row 2 */}
          <div className="how-it-works-row row">
            <div className="step-para col-sm-6 order-sm-0 order-1">
              <p>
                <span style={{ color: "#009598" }}>Resource</span> Hub
              </p>
              <p>
                Explore our Resources Hub, a curated collection of downloadable
                books and content to enrich your learning.
              </p>
              <p>
                Dive into diverse topics and gain valuable insights with every
                download, designed to fuel your passion and expertise.
              </p>
              <a href="https://resource.prepcrazy.com/">Visit now</a>
            </div>

            <div className=" col-sm-6 position-relative order-sm-1 order-0">
              {/* <img
                src={process.env.PUBLIC_URL + "/assets/images/step-2-g.svg"}
                alt=""
              /> */}
              <img
                className={classes.image_2}
                src="/assets/images/resource.svg"
                alt=""
                width={600}
                height={500}
              />
            </div>
          </div>

          {/* row 3 */}

          {/* <div className="how-it-works-row row">
            <div className=" col-sm-6 position-relative">
             
              <img
                className={classes.image_3}
                src="/assets/images/blog.svg"
                alt=""
                width={600}
                height={500}
              />
            </div> */}

          {/* <div className="step-para col-sm-6">
              <p>
                Tech <span style={{ color: "#009598" }}>Insights</span> Hub
              </p>
              <p>
                Explore the Tech Insights Hub for a curated selection of
                articles that illuminate the evolving digital landscape.
              </p>
              <p>
                Our blog serves as a bridge to the future of technology,
                offering readers a platform to learn, grow, and be inspired.
              </p>
              <a href="https://read.careercredentials.in/">Read now</a>
            </div>
          </div> */}

          <div className="statistics">
            <div className="statistics-row row">
              <div
                id="statistics-col-1"
                className="statistics-col statistics-col-border col-sm-3 col-6"
              >
                <i className="ri-registered-fill"></i>
                <p>2000+</p>
                <span>Registered students</span>
              </div>
              <div
                id="statistics-col-2"
                className="statistics-col statistics-col-border col-sm-3 col-6"
              >
                <i className="ri-user-3-fill"></i>
                <p>100+</p>
                <span>Active students</span>
              </div>
              <div
                id="statistics-col-3"
                className="statistics-col statistics-col-border col-sm-3 col-6"
              >
                <i className="ri-checkbox-circle-fill"></i>
                <p>1K+</p>
                <span>Doubt solved</span>
              </div>
              <div
                id="statistics-col-4"
                className="statistics-col col-sm-3 col-6"
              >
                <i className="ri-user-2-fill"></i>
                <p>15</p>
                <span>Active teachers</span>
              </div>
            </div>
          </div>

          <div className="testimonials">
            <p className={classes.title_test}>Testimonials</p>
            <p className={classes.desc_test}>
              Hear from Our Community with Real Stories and Real Success. Dive
              into testimonials from users who've transformed their tech journey
              with us. Discover how our platform ignites passion and drives
              success through breakthroughs and innovations.
            </p>
          </div>

          <div className="testimonials-row row">
            <div className="testimonials-col col-sm-6">
              <div className="testimonials-inner-col">
                <p className="testimonials-review">
                  "Thanks to the coding platform, I aced my technical interview!
                  Practicing on real-world coding challenges prepared me for
                  anything they threw at me. It's like having a personal coding
                  coach right at my fingertips."
                </p>
                <div className="testimonials-user-details">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/t-1.png"}
                    alt=""
                  />
                  <div>
                    <p className="testimonials-user-name">Rajesh Patel </p>
                    <p className="testimonials-user-class">Mumbai</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonials-col col-sm-6">
              <div className="testimonials-inner-col">
                <p className="testimonials-review">
                  "The blog section on this platform has been incredibly
                  helpful. I've learned so much from the practical advice and
                  real-world examples shared there. It's like having a wise
                  friend who's been through it all, giving me tips to succeed."
                </p>
                <div className="testimonials-user-details">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/t-2.png"}
                    alt=""
                  />
                  <div>
                    <p className="testimonials-user-name">Vikram Singh</p>
                    <p className="testimonials-user-class">Mumbai</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonials-col col-sm-6">
              <div className="testimonials-inner-col">
                <p className="testimonials-review">
                  "The resources section here has been a game-changer for my
                  coding journey. I've found everything from beginner tutorials
                  to advanced courses, all tailored to my needs. It's like
                  having a personal library of knowledge right at my
                  fingertips."
                </p>
                <div className="testimonials-user-details">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/t-3.png"}
                    alt=""
                  />
                  <div>
                    <p className="testimonials-user-name">Priya Sharma</p>
                    <p className="testimonials-user-class">Mumbai</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="testimonials-col col-sm-6">
              <div className="testimonials-inner-col">
                <p className="testimonials-review">
                  "With this coding platform, I've been able to level up my
                  skills and land better opportunities. It's been a real
                  confidence booster, knowing I have access to tools and
                  guidance that make learning and growing easier. Forever
                  grateful for their guidance and support."
                </p>
                <div className="testimonials-user-details">
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/t-4.png"}
                    alt=""
                  />
                  <div>
                    <p className="testimonials-user-name">Samay doiphode</p>
                    <p className="testimonials-user-class">Mumbai</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Faq section */}
          <div className="faq">
            <p className={classes.faqtitle}>FAQs</p>
          </div>

          <div className="faq-question">
            <a className="faq-question-div">
              <i
                className="ri-add-circle-line d-block"
                onClick={() => toggleFaq("faqCollpase1")}
                data-bs-toggle="collapse"
                href="#faqCollpase1"
                role="button"
                aria-expanded={activeFaq === "faqCollapse1"}
                aria-controls="faqCollpase1"
                tabIndex="0"
              ></i>
              <p className="faq-question-button">
                What resources can I find in the Resources Hub?
              </p>
              <p
                className={`faq-answer collapse ${
                  activeFaq === "faqCollpase1" ? "show" : ""
                }`}
                id="faqCollpase1"
              >
                Our Resources Hub offers a wide array of downloadable books and
                content covering various tech topics. From beginner guides to
                advanced tutorials, it's designed to support your learning
                journey at every level.{" "}
              </p>
            </a>

            <a className="faq-question-div">
              <i
                className="ri-add-circle-line d-block"
                onClick={() => toggleFaq("faqCollpase2")}
                data-bs-toggle="collapse"
                href="#faqCollpase2"
                role="button"
                aria-expanded={activeFaq === "faqCollapse2"}
                aria-controls="faqCollpase2"
                tabIndex="0"
              ></i>
              <p className="faq-question-button">
                How does the Code Compiler Playground work?
              </p>
              <p
                className={`faq-answer collapse ${
                  activeFaq === "faqCollpase2" ? "show" : ""
                }`}
                id="faqCollpase2"
              >
                Code Compiler Central allows you to write, test, and refine your
                code in real-time. It supports multiple programming languages,
                providing an intuitive platform for both learning and basic
                project development. Simply enter your code, run it, and see the
                output instantly.{" "}
              </p>
            </a>

            <a className="faq-question-div">
              <i
                className="ri-add-circle-line d-block"
                onClick={() => toggleFaq("faqCollpase3")}
                data-bs-toggle="collapse"
                href="#faqCollpase3"
                role="button"
                aria-expanded={activeFaq === "faqCollapse3"}
                aria-controls="faqCollpase3"
                tabIndex="0"
              ></i>
              <p className="faq-question-button">
                What topics are covered in the Tech Blog?
              </p>
              <p
                className={`faq-answer collapse ${
                  activeFaq === "faqCollpase3" ? "show" : ""
                }`}
                id="faqCollpase3"
              >
                The Insightful Tech Blog covers a broad spectrum of
                technology-related topics, including the latest industry trends,
                software development best practices, innovative tools, and
                in-depth analyses of current tech phenomena. It's a treasure
                trove for anyone looking to stay updated or find inspiration.{" "}
              </p>
            </a>

            <a className="faq-question-div">
              <i
                className="ri-add-circle-line d-block"
                onClick={() => toggleFaq("faqCollpase4")}
                data-bs-toggle="collapse"
                href="#faqCollpase4"
                role="button"
                aria-expanded={activeFaq === "faqCollapse4"}
                aria-controls="faqCollpase4"
                tabIndex="0"
              ></i>
              <p className="faq-question-button">
                How do I join the community on Telegram?
              </p>
              <p
                className={`faq-answer collapse ${
                  activeFaq === "faqCollpase4" ? "show" : ""
                }`}
                id="faqCollpase4"
              >
                To join our community on Telegram, click{" "}
                <a
                  href="https://t.me/careercredentials"
                  onClick={(e) => {
                    console.log("Link clicked, stopping propagation");
                    e.stopPropagation();
                  }}
                  style={{ "text-decoration": "none" }}
                >
                  "here" .
                </a>{" "}
                You'll be redirected to our group where you can subscribe to
                receive updates, join discussions, and connect with like-minded
                individuals.{" "}
              </p>
            </a>

            <a className="faq-question-div">
              <i
                className="ri-add-circle-line d-block"
                onClick={() => toggleFaq("faqCollpase5")}
                data-bs-toggle="collapse"
                href="#faqCollpase5"
                role="button"
                aria-expanded={activeFaq === "faqCollapse5"}
                aria-controls="faqCollpase5"
                tabIndex="0"
                id="i5"
              ></i>
              <p className="faq-question-button">
                Can I contribute to the blog or suggest resources?
              </p>
              <p
                className={`faq-answer collapse ${
                  activeFaq === "faqCollpase5" ? "show" : ""
                }`}
                id="faqCollpase5"
              >
                Absolutely! We welcome contributions and suggestions from our
                community. If you have insightful content for the blog or
                valuable resources to share, please contact us through the
                &nbsp;
                <a
                  href="mailto:support@prepcrazy.com"
                  style={{ "text-decoration": "none" }}
                  onClick={(e) => {
                    console.log("Link clicked, stopping propagation");
                    e.stopPropagation();
                  }}
                >
                  email.
                </a>{" "}
                We're always looking to expand our content with the help of our
                talented community.{" "}
              </p>
            </a>
          </div>
          {/* 
          <div className="download-section">
            <div className="download-row row">
              <div className="download-col col-sm-6">
                <p className="download-heading">Download App</p>
                <p className="download-description">
                  Solve your doubt within an hours lorem ipsum lorem ipsum lorem
                  ispsum Solve your doubt within an hours orem ipsum lorem ipsum
                  lorem ispsum psum. Solve your doubt within an hours lorem
                  ipsum lorem ipsum lorem ispsum.
                </p>
                <div className="download-playbtn">
                  <a href="##">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/app-store.svg"
                      }
                      alt=""
                    />
                  </a>
                  <a href="##">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/play-store.png"
                      }
                      alt=""
                    />
                  </a>
                </div>
              </div>

              <div className="download-col col-sm-3 d-none d-sm-flex">
                <img
                  className="download-mockup"
                  src="https://ik.imagekit.io/99djpd8k3/Read%20Snap.png?updatedAt=1707135614745"
                  alt=""
                />
              </div>
            </div>
          </div> */}

          <div className="footer">
            <div className="footer-row row">
              <div className="footer-col col-md-4 col-sm-6">
                {/* <div className="footer-inner-col">
                  <p className="footer-heading">Prepcrazy</p>
                  <p className="footer-description footer-description2">
                    Solve your doubt within an hours lorem ipsum lorem ipsum
                    lorem ispsum Solve your doubt within an hours orem ipsum. t
                    within an hours orem ipsum.
                  </p>
                </div> */}
              </div>
              <div className="footer-col col-md-5 col-sm-6">
                {/* <div className="footer-inner-col">
                  <p className="footer-heading">Newsletter</p>
                  <p className="footer-description">
                    Solve your doubt within an hours lorem ipsum lorem
                  </p>
                  <form className="footer-form">
                    <input type="email" placeholder="Your email" />
                    <button>Submit</button>
                  </form>
                </div> */}
              </div>
              <div className="footer-col col-md-3 col-sm-12">
                {/* <div className="footer-inner-col">
                  <p className="footer-heading">Follow us</p>
                  <ul className="social-media-list">
                    <a href="##">
                      <li>Instagram</li>
                    </a>
                    <a href="##">
                      <li>Linkedin</li>
                    </a>
                    <a href="##">
                      <li>Twitter</li>
                    </a>
                    <a href="##">
                      <li>Facebook</li>
                    </a>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.copyright}>
          <img
            src="/assets/images/logo.png"
            width={250}
            height={40}
            className={classes.footlogo}
            alt=""
          />
          <p>Copyright @2024 All Right Reserved</p>
          <p>
            <MdEmail />
            &nbsp;
            <a
              style={{ "text-decoration": "none", color: "white" }}
              href="mailto:support@prepcrazy.com"
            >
              support@prepcrazy.com
            </a>
          </p>
          <p>
            An initiative by{" "}
            <a
              href="https://campuscredentials.com/"
              style={{ "text-decoration": "none", color: "white" }}
            >
              Campus Credentials
            </a>{" "}
          </p>
        </div>
      </div>
      {/* home - end */}
    </>
  );
};

export default Home;
