import React from 'react';
import { Home, Signin, Signup } from './components';
import { Routes, Route } from "react-router-dom";
import {HeroText}  from './components/Hero/Hero';
function App() {
  return (
    <>

      <Routes>
      <Route path="/" element={<Home /> } />

 
     
        {/* <Route path="/" element={<Home />} /> */}
       
      
        {/* <Route path="/signup" element={<Signup />} />
        <Route path="/signin" element={<Signin />} /> */}
      </Routes>
    </>
  );
}

export default App;
