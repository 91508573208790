import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { MantineProvider } from '@mantine/core';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
    <MantineProvider
      theme={{ /* Your theme configuration here, if any */ }}
      withGlobalStyles
      withNormalizeCSS
    >
      <App />
      </MantineProvider>
    </Router>
  </React.StrictMode>
);

